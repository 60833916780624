<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            5 új szokás a jobb offline-online egyensúlyért
          </h2>
          <p class="hashText">-Tudatos jelenlét az online térben</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Az online világ és a modern digitális technológiák egyre kényelmesebb és szórakoztatóbb alternatívákat nyújtanak az élet szinte minden területére, a munka világától elkezdve egészen a társas életig. Ezek az eszközök tehát fontos részévé váltak mindennapi életünknek, azonban egyre többen tapasztalják, hogy az online tevékenységek túlzott mértékben veszik át az irányítást az életünk felett. Akár az édességevésben vagy a testedzésben, itt is túlzásokba lehet esni az „élvezetekkel” - főként, amikor hajnali órákban eszmélünk rá, hogy ma sem lesz pihentető alvásunk egy átmobilozott éjszaka után. Azáltal, hogy ekkora szeletét töltjük az életünknek a világhálón, egyre fontosabbá válik, hogy figyeljünk az offline-online jelenlétünk egyensúlyára, és megpróbáljuk kialakítani azokat az egészséges szokásokat, amelyek segítenek minket a mindennapokban. </p>

                <p class="text">
                Az alábbiakban öt új szokást írtunk össze a jobb offline-online egyensúlyért:</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/otos.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Célzott online tevékenységek:</b> Az egyensúly eléréséhez fontos, hogy előre megtervezzük az online tevékenységeinket, és csak a fontos dolgokra koncentráljunk. Határozzuk meg, hogy milyen okokból vagyunk online, például pár e-mail elküldése, kapcsolattartás vagy éppen információszerzés, és csak ezekre fókuszáljunk.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Telefonmentes időszakok:</b>A telefonok és az online eszközök szinte állandóan velünk vannak, és gyakran elvonják a figyelmünket az offline tevékenységektől, például a minőségi együttlétektől a szeretteinkkel. Érdemes ezért tudatosan bevezetni néhány telefonmentes időszakot a napunkba. Ez lehet például az étkezések ideje, vagy az esti relaxáció időszaka is. Az alvásunk minőségén is javíthat, ha elalvás előtt pár órával már nem találkozunk világító képernyőkkel.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Tudatos online idő:</b> Az online világban eltöltött idő jelentős része általában passzív tevékenységekből áll, például a közösségi média böngészéséből vagy a videók nézéséből, érdemes azonban időt szánni tudatos online tevékenységekre is, például az online tanulásra vagy a személyes kapcsolatok ápolására. Az egyensúlyt ugyanis nemcsak az offline töltött idő mennyiségének növelésével, hanem az online töltött idő minőségi kihasználásával is létre lehet hozni. Ehhez segítségünkre lehet a telefonokon elérhető Digitális jóllét funkció. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Mozgás és szabadidős tevékenységek:</b> Az aktív offline tevékenységek, mint például a sport, a séta vagy a művészeti tevékenységek fontosak az egészséges életmód és stresszlevezetés szempontjából. Érdemes ezeket beépíteni a napi rutinunkba, és az online böngészés mellett rendszeresen mozogni és időt szánni a hobbijainkra, kreativitásunk és energiáink offline kibontakoztatására. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Alvás és pihenés:</b> Az online világban eltöltött idő gyakran csökkenti az alvás és a pihenés minőségét. Ennek több oka is lehet: egyrészt a képernyők fontos jellemzője, a kékfény kibocsátás, másrészt az egyoldalú leterheltség. Az előbbit kékfényszűrővel csökkenthetjük: ez a beállítás megtalálható mind az újabb számítógépek, mind a mobiltelefonok esetén.</p>
                </li>
              </ul>
            </p>
            <p class="text">A fentiek napi rutinba történő beépítésével könnyebben elérhetjük, hogy a telefont valóban csak akkor vegyük elő, ha szükségünk van rá, és ne üres időtöltésként vagy alvási rutinként tekintsünk az eszközünkre. </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "5 új szokás a jobb offline-online egyensúlyérte", 
        desc: "Az online világ és a modern digitális technológiák egyre kényelmesebb és szórakoztatóbb alternatívákat nyújtanak az élet szinte minden területére, a munka világától elkezdve egészen a társas életig.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "5 új szokás a jobb offline-online egyensúlyért",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az online világ és a modern digitális technológiák egyre kényelmesebb és szórakoztatóbb alternatívákat nyújtanak az élet szinte minden területére, a munka világától elkezdve egészen a társas életig."        
    })
  }
}
</script>